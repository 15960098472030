<template>
  <div class="home">
    <top-bar></top-bar>
    <nav-bar :id="0"></nav-bar>
    <div class="topBody">
      <van-row>
        <van-col span="24"><h2>Hi~{{userName}}</h2></van-col>
      </van-row>
      <van-row>
        <van-col span="24"><span class="tip">{{orgName}} 祝你开心每一天</span></van-col>
      </van-row>
      <van-grid :border="false"  :column-num="3">
            <van-grid-item   v-for="item2 in questionsList" :key="item2.name"  >
              <div>
                <p class="topGridP">{{item2.value}}</p>
                <span>{{item2.name}}</span>
              </div>
             </van-grid-item>
        </van-grid>
      <van-row>
        <van-col span="24">
          <div class="banner">
            <div class="bannerFont">
              <p>让生活更美好</p>
              <span>{{orgName}}   美好社区</span>
            </div>
            <img src="@/assets/img/home-banner@2x.png" alt="">
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="cont">
      <ul>
        <li v-for="item in dataList" :key="item.name">
          <h4>{{item.name}}</h4>
          <van-grid :border="false"  square>
            <van-grid-item   v-for="item2 in item.childList" :key="item2.name"  @click="clickFn(item2.url)">
              <img :src="item2.img" alt="" class="gridImg">
              <p >{{item2.name}}</p>
             </van-grid-item>
          </van-grid>
        </li>
      </ul>
    </div>

    <div class="taskReminder">
      <h4>工作任务提醒</h4>
      <van-row>
        <van-col span="8">
          <h4><i>0</i>/1</h4>
          <p>本日工作进度</p>
        </van-col>
        <van-col span="8">
          <h4><i>0</i>/2<span></span></h4>
          <p>本周工作进度</p>
        </van-col>
        <van-col span="8">
          <h4><i>1</i>/4</h4>
          <p>本月工作进度</p>
        </van-col>
      </van-row>
    </div>

    <div class="assignReminder">
      <h4>交办任务提醒 <span>更多<van-icon name="arrow" /></span></h4>
      <van-cell-group >
        <van-cell class="title" center>
          <span>子火棋牌室</span><span class="right">(巡查类)</span>
          <span>2021-06-01 15:00</span>
        </van-cell>
        <van-cell class="title" center>
          <span>亨利理发店</span><span class="right">(巡查类)</span>
          <span>2021-05-31 15:00</span>
        </van-cell>
      </van-cell-group>
    </div>
<!--    <div class="bottomTip">-->
<!--        <p>本服务由浙江政务服务网、杭州优户通科技有限公司提供 <br/> 服务咨询热线：0571-89965900</p>-->
<!--    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src
import topBar from '@/components/topBar/topBar'
import navBar from '@/components/navBar/navBar'
import Vue from 'vue'

export default {
  name: 'Home',
  data () {
    return {
      orgName:'',
      userName:'',
      page: 1,
      limit: 1,
      questionsList : [
        {name:'问题总数', value: 0 },
        {name:'社区活动', value: 0 },
        {name:'办事指南', value: 0 },
        // {name:'维修总数', value: 0 },
        ],
        dataList: [
          {
            name: '社区共治',
            childList: [
              {img:require('@/assets/img/community1@2x.png'), name: '公告', url: '/notice'},
              {img:require('@/assets/img/menu_5.png'), name: '问题', url: '/problem'},
              {img:require('@/assets/img/community3@2x.png'), name: '办事指南', url: '/guide'},
              {img:require('@/assets/img/menu_1.png'), name: '活动', url: '/activity'},
              {img:require('@/assets/img/menu_4.png'), name: '报修', url: '/repair'},
              {img:require('@/assets/img/menu_2.png'), name: '走访', url: '/visit'},
              {img:require('@/assets/img/menu_3.png'), name: '巡查', url: '/patrol'},
              {img:require('@/assets/img/menu_3.png'), name: '场所管理', url: '/place'},
              {img:require('@/assets/img/menu_3.png'), name: '设备管理', url: '/device'},
              {img:require('@/assets/img/menu_3.png'), name: '交办任务', url: '/mission'},
              {img:require('@/assets/img/menu_3.png'), name: '工作任务', url: '/tasks'},
            ]
          }
        ]
    }
  },
  components: {
    topBar,
    navBar
  },
  methods: {
    init () {
      // 问题总数
      this.$http({
          url: this.$http.adornUrl('/wxapp/problem/list'),
          method: 'post',
          params: this.$http.adornParams({
            page: this.page,
            limit: this.limit,
            orgId: this.$orgId,
            userId: this.$userId
          })
      }).then(({data})=> {
        if (data.code == 0) {
        this.questionsList[0].value = data.list.totalCount
        }
      })
      // 维修总数
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/repair/info/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          depath: 5,
          orgId: this.$orgId,
          userId: this.$userId
        })
      }).then(({data})=> {
        if (data.code == 0) {}
        this.questionsList[1].value = data.page.totalCount
      })
      // 社区活动数
      // this.$http({
      //   url: this.$http.adornUrl('/wxapp/activity/list'),
      //   method: 'post',
      //   params: this.$http.adornParams({
      //     page: this.page,
      //     limit: this.limit,
      //     title: '',  //搜索条件
      //     type: '',        //活动类型
      //     beginTime: '',   //活动开始时间
      //     endTime: '',
      //     orgId: this.$orgId
      //   })
      // }).then(({data})=> {
      //   if (data.code == 0) {
      //   this.questionsList[3].value = data.page.totalCount
      //   }
      // })
      // 办事指南
      this.$http({
        url: this.$http.adornUrl('/wxapp/guide/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          appOrgId: this.$orgId,
          searchValue: ''
        })
      }).then(({data})=> {
        if (data.code == 0) {
        this.questionsList[2].value = data.page.totalCount
        }
      })
    },
    clickFn (url) {
      this.$router.push(url)
    }
  },
  created () {
    this.userName = this.$userName
    this.orgName = this.$orgName
    this.init()
  }
}
</script>
<style>
 .van-grid-item__content {
   padding: 0;
 }
</style>
<style lang="scss" scoped>
.van-grid-item__content {
  padding: 0;
}
  .home {
    //padding-top: 100px;
    background-color: #f5f5f5;
    .fr {
      float: right !important;
    }
    .topBody {
      background-color: #fff;
      // padding: 32px 44px;
      h2 {
        padding: 32px 44px 0;
        font-size: 40px;
        color: #333;
        line-height: 50px;
        margin: 0;
      }
      .tip {
        margin-left: 44px;
      }
      span {
        display: block;
        padding: 15px 0;
        color: #999999;
        font-size: 30px;
      }
      .topGridP {
         margin: 0;
          font-size: 44px;
          text-align: center;
      }
      .questionItem {
        float: left;
        p {
          margin: 0;
          font-size: 36px;
        }
      }
      .isEndItem {
        margin-right: 90px;
      }
      .banner {
        width: 100%;
        padding: 10px 44px 30px;
        position: relative;
        img {
          width: 100%;
        }
        .bannerFont {
          position: absolute;
          top: 45px;
          left: 70px;
          p {
            font-size: 42px;
            font-weight: 800;
            background: linear-gradient(0deg, #67AAFF 0%, #FFFFFF 54.6630859375%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          span {
            background: linear-gradient(0deg, #67AAFF 0%, #FFFFFF 54.6630859375%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 0;
            font-size: 30px;
          }
        }
      }
    }
    .cont {
      overflow: hidden;
      margin-top: 20px;
      // padding: 40px;
      background-color: #fff;
      ul {
        width: 100%;
        li {
          h4 {
            font-size: 30px;
            margin: 30px 44px;
            // margin-bottom: 30px;
          }
          .gridImg {
            width: 90px;
          }
          p {
            font-size: 26px;
          }
        }
      }
    }
    .taskReminder {
      overflow: hidden;
      height: 220px;
      margin-top: 20px;
      background-color: #fff;
      h4 {
        font-size: 30px;
        margin: 30px 0 0 44px;
      }
      .van-row {
        margin-left: 80px;
       .van-col {
         position: relative;
         h4 {
           font-size: 35px;
           font-weight: 500;
         }
         i {
           font-style: normal;
           color: #3981fc;
         }
         span {
           display: block;
           position: absolute;
           top: 15px;
           right: 53%;
           width: 13px;
           height: 13px;
           border-radius: 100%;
           background-color: red;
         }
         p {
           color: #808080;
         }
       }
      }
    }
    .assignReminder {
      overflow: hidden;
      height: 420px;
      margin-top: 20px;
      background-color: #fff;
      h4 {
        position: relative;
        font-size: 30px;
        margin: 30px 0 0 44px;
        span {
          position: absolute;
          top: 0;
          right: 35px;
          font-size: 25px;
          color: #999999;
          .van-icon {
            vertical-align: top;
          }
        }
      }
     .title {
       position: relative;
       font-size: 28px;
       padding-left: 60px;
       span:nth-child(3) {
         position: absolute;
         top: 0;
         right: 10px;
       }
     }
      .van-cell::after {
        border-bottom: none;
      }
      [class*=van-hairline]::after {
        border: none;
      }
    }
    .bottomTip {
      background-color: #fff;
      text-align: center;
      color: #666;
      font-size: 24px;
      line-height: 50px;
      padding-top: 35px;
      padding-bottom: 180px;
      p {
        margin: 0;
      }
    }
  }
</style>

